import { HStack, VStack } from '@neui/layout';
import {
  Typography,
  Headline,
  interaction_arrows_arrowRight,
} from '@neui/styleguide-commerzbank';
import { InView } from 'react-intersection-observer';

import { StyledSection } from 'page-templates/OBIndexPage';
import { useTranslation } from '@utils/i18n';
import { useRuntimeSettings } from '@utils/config';
import { Image } from '@components/Image';
import { useTracker } from '@utils/snowplowTracking';
import { GA4TrackLinkClick } from '@utils/tracking';
import { handleInViewChange } from '@utils/helpers/misc';

import { IconLink } from './neui-components/atoms/IconLink';

export const ChangeServicePortal = () => {
  const {
    staticContent: {
      switchPortal: { url: iconUrl, headlineKey: translationKey },
    },
    tracking: {
      ga4: { enabled: enableGA4Tracking, eventContext },
    },
  } = useRuntimeSettings();
  const { $t } = useTranslation();
  const { trackLinkClick } = useTracker(ChangeServicePortal.name);

  const handleChangeServicePortalClick = () => {
    enableGA4Tracking &&
      GA4TrackLinkClick(
        eventContext,
        iconUrl,
        $t('CHANGE_SERVICEPORTAL_ICONLINK'),
        undefined,
        'Homepage',
        undefined,
        'Portal Change',
        4,
        4,
      );
    trackLinkClick?.(iconUrl, $t('CHANGE_SERVICEPORTAL_ICONLINK'));
  };
  return (
    <InView
      onChange={handleInViewChange(
        'Portal Change',
        4,
        4,
        enableGA4Tracking,
        eventContext,
      )}
      triggerOnce={true}
    >
      {({ ref }) => (
        <StyledSection ref={ref}>
          <VStack
            flexDirection={{ base: 'column', md: 'row-reverse' }}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <HStack justifyContent={'end'} paddingRight={{ sm: 0, md: 50 }}>
              <Image src="/icons/office-tree.svg" loading="lazy" alt="" />
            </HStack>
            <HStack alignItems={'center'}>
              <VStack spacing={24}>
                <Headline type="h3">{$t(translationKey as any)}</Headline>
                <Typography
                  size={5}
                  weight={'book'}
                  aria-level={3}
                  role="heading"
                >
                  {$t('CHANGE_SERVICEPORTAL_SUBLINE')}
                </Typography>
                <IconLink
                  iconPosition="right"
                  icon={interaction_arrows_arrowRight}
                  animationDirection="right"
                  css={{ width: 'fit-content' }}
                  href={iconUrl}
                  onClick={handleChangeServicePortalClick}
                >
                  {$t('CHANGE_SERVICEPORTAL_ICONLINK')}
                </IconLink>
              </VStack>
            </HStack>
          </VStack>
        </StyledSection>
      )}
    </InView>
  );
};
