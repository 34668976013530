import { SimpleGrid, VStack } from '@neui/layout';
import {
  Typography,
  Headline as NeuiHeadline,
  Text,
  interaction_arrows_arrowRight,
} from '@neui/styleguide-commerzbank';
import { InView } from 'react-intersection-observer';

import { Section } from '@components/neui-components/atoms/Section';
import { TeaserCard } from '@components/neui-components/molecules/TeaserCard';
import { useTracker } from '@utils/snowplowTracking';
import { GA4TrackTeaserCtaClick } from '@utils/tracking';
import { CategoryPageProps } from 'pages/[...slug]';
import { Image } from '@components/Image';
import { useMakeLink } from '@components/Link';
import { ImageWrapper, StyledIconLink } from 'page-templates/FkIndexPage';
import { handleInViewChange } from '@utils/helpers/misc';
import { useRuntimeSettings } from '@utils/config';
import { useTranslation } from '@utils/i18n';

export type CategoriesProps = {
  topCategories: CategoryPageProps[];
  sectionNumber: number;
  sectionTotal: number;
};

export const Categories = ({
  topCategories,
  sectionNumber,
  sectionTotal,
}: CategoriesProps) => {
  const {
    staticContent: { categories },
    tracking: {
      ga4: { enabled: enableGA4Tracking, eventContext },
    },
  } = useRuntimeSettings();
  const { $t } = useTranslation();

  const { trackButtonClick } = useTracker(Categories.name);
  const makeLink = useMakeLink();

  return (
    <InView
      onChange={handleInViewChange(
        'Unterstützung & Informationen',
        sectionNumber,
        sectionTotal,
        enableGA4Tracking,
        eventContext,
      )}
      triggerOnce={true}
    >
      {({ ref }) => (
        <Section ref={ref}>
          <VStack spacing={'$subsection'}>
            <NeuiHeadline
              type="h3"
              renderAs="h2"
              subline={$t('INDEX_PAGE_SUBLINE')}
            >
              {$t('INDEX_PAGE_HEADLINE')}
            </NeuiHeadline>
            <SimpleGrid
              spacing={{ base: '$space$component-8' }}
              columns={{ base: 1, xs: 1, sm: 2, md: 3 }}
            >
              {topCategories.map((category, index) => {
                const { slug, title } = category;
                const categoryInfo = categories.find(([s]) => s === slug)?.[1];
                if (categoryInfo === undefined) {
                  return null;
                }

                const onClick = (
                  _e: React.MouseEvent<HTMLElement, MouseEvent>,
                ) => {
                  // e?.preventDefault();
                  trackButtonClick?.(title, 'category', []);
                  GA4TrackTeaserCtaClick(
                    eventContext,
                    'CTA Picture',
                    title,
                    'Mehr erfahren',
                    makeLink({
                      href: slug,
                      absoluteUrl: true,
                      alwaysPrependBasePath: true,
                    }),
                    'Unterstützung & Informationen',
                    sectionNumber,
                    sectionTotal,
                  );
                  // navigate(slug, router);
                };
                return (
                  <TeaserCard
                    key={index}
                    href={slug}
                    onClick={onClick}
                    hover
                    bordered
                    picture={
                      <ImageWrapper>
                        <Image
                          src={categoryInfo.icon as string}
                          alt=""
                          loading="lazy"
                        />
                      </ImageWrapper>
                    }
                    footer={
                      <StyledIconLink
                        iconPosition="right"
                        animationDirection="none"
                        renderAs={Typography}
                        icon={interaction_arrows_arrowRight}
                        data-cy={'category'}
                      >
                        Mehr erfahren
                      </StyledIconLink>
                    }
                  >
                    <VStack spacing={{ base: 8, md: 16 }}>
                      <Typography
                        size={4}
                        weight={'medium'}
                        aria-level={3}
                        role="heading"
                      >
                        {title}
                      </Typography>
                      <Text textAlign={'left'} type="info">
                        {categoryInfo.description}
                      </Text>
                    </VStack>
                  </TeaserCard>
                );
              })}
            </SimpleGrid>
          </VStack>
        </Section>
      )}
    </InView>
  );
};
