import React, { useState } from 'react';
import { VStack, SimpleGrid } from '@neui/layout';
import {
  sandTheme,
  Typography,
  Headline as NeuiHeadline,
  interaction_arrows_arrowRight,
} from '@neui/styleguide-commerzbank';
import { styled } from '@neui/core';
import { InView } from 'react-intersection-observer';

import { Section } from '@components/neui-components/atoms/Section';
import {
  Accordion,
  AccordionComponentItemProps,
} from '@components/neui-components/molecules/Accordion';
import { Layer } from '@components/neui-components/molecules/Layer';
import { TeaserCard } from '@components/neui-components/molecules/TeaserCard';
import {
  GA4TrackSideLayerOpen,
  GA4TrackLinkClick,
  GA4TrackAccordionOpen,
} from '@utils/tracking';
import { ClickItem } from '@components/neui-components/atoms/ClickItem';
import {
  getOnlineBankingServices,
  OnlineBankingServicesProps,
} from '@static-content/OB-services';
import { useTranslation } from '@utils/i18n';
import { IconLink, Wrapper } from '@components/neui-components/atoms/IconLink';
import { makeStagedLinkFunction } from '@utils/helpers/linkHelpers';
import { useRuntimeSettings } from '@utils/config';
import { useTracker } from '@utils/snowplowTracking';
import { handleInViewChange } from '@utils/helpers/misc';

export type OBServicesProps = {};

export const OBServices = () => {
  const {
    build: { stage },
    tracking: {
      ga4: { enabled: enableGA4Tracking, eventContext },
    },
  } = useRuntimeSettings();
  const { $t } = useTranslation();
  const [isOpen, openLayer] = useState(false);

  const makeStagedLink = makeStagedLinkFunction(stage);

  const onlineBankingServices = getOnlineBankingServices(makeStagedLink);

  const [serviceTitle, setServiceTitle] = useState(
    onlineBankingServices[0].title,
  );
  const [idx, setIdx] = useState(0);

  const handleAccordionValueChange = (value: string | null) => {
    if (value) {
      GA4TrackAccordionOpen(eventContext, serviceTitle, value);
    }
  };

  const { trackLinkClick } = useTracker(OBServices.name);

  const getSubcategory = (index: number) =>
    onlineBankingServices[idx].categories[index].subcategories.map(
      (subcategory) => (
        <>
          <VStack spacing={'$component-5'}>
            <Typography>{subcategory.subcategoryTitle}</Typography>

            <VStack
              alignItems={'flex-start'}
              css={{ marginBottom: '$component-6', padding: '0 2px' }}
            >
              {subcategory.links.map((link, i) => (
                <ClickItem
                  key={i}
                  href={link.href}
                  onClick={() => {
                    trackLinkClick?.(link.href, link.linkTitle);
                    GA4TrackLinkClick(
                      eventContext,
                      link.href,
                      link.linkTitle,
                      onlineBankingServices[idx].title,
                      'Sidelayer',
                      onlineBankingServices[idx].categories[index]
                        .categoryTitle,
                    );
                  }}
                >
                  <Typography weight={'book'}>{link.linkTitle}</Typography>
                </ClickItem>
              ))}
            </VStack>
          </VStack>
        </>
      ),
    );

  function transformItems(
    items: OnlineBankingServicesProps,
  ): AccordionComponentItemProps[] {
    return items.categories.map((item, categoryTitleIndex) => ({
      label: item.categoryTitle,
      children: getSubcategory(categoryTitleIndex),
      titleAs: 'h3',
    }));
  }

  return (
    <InView
      onChange={handleInViewChange(
        'Services im Online Banking',
        2,
        3,
        enableGA4Tracking,
        eventContext,
      )}
      triggerOnce={true}
    >
      {({ ref }) => (
        <SandSection theme={'sand'} className={sandTheme} ref={ref}>
          <NeuiHeadline
            type="h3"
            renderAs="h2"
            textAlign={'left'}
            css={{ alignSelf: 'flex-start' }}
          >
            Services im Online Banking
          </NeuiHeadline>
          <SimpleGrid
            spacing={{ base: '$space$component-8' }}
            columns={{ base: 1, xs: 1, sm: 2, md: 3 }}
          >
            {onlineBankingServices.map((service, index) => {
              const onClick = (
                e: React.MouseEvent<HTMLElement, MouseEvent>,
              ) => {
                e.preventDefault();
                GA4TrackSideLayerOpen(eventContext, service.title);
                openLayer(true);
                setServiceTitle(service.title);
                setIdx(index);
              };

              const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
                if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                  e.preventDefault();
                  openLayer(true);
                  GA4TrackSideLayerOpen(eventContext, service.title);
                  setServiceTitle(service.title);
                  setIdx(index);
                }
              };

              return (
                <StyledTeaserCard
                  key={index}
                  onClick={onClick}
                  onKeyDown={onKeyDown}
                  tabIndex={0}
                  hover
                  footer={
                    <StyledIconLink
                      iconPosition="right"
                      animationDirection="none"
                      renderAs={Typography}
                      icon={interaction_arrows_arrowRight}
                      data-cy={'top-article'}
                    >
                      Zu den Direktlinks
                    </StyledIconLink>
                  }
                >
                  <VStack spacing={{ base: 12, md: 16 }}>
                    <Typography
                      size={4}
                      weight={'medium'}
                      role="heading"
                      aria-level={3}
                    >
                      {service.title}
                    </Typography>
                    <Typography textAlign={'left'}>
                      {service.excerpt}
                    </Typography>
                  </VStack>
                </StyledTeaserCard>
              );
            })}
            <Layer
              closeLabel={$t('BACK')}
              open={isOpen}
              onOpenChange={() => openLayer(false)}
              css={{ zIndex: '100' }}
            >
              <VStack spacing={64}>
                <VStack spacing={24}>
                  <NeuiHeadline type="h3">{serviceTitle}</NeuiHeadline>
                  <Accordion
                    type="single"
                    collapsible
                    items={transformItems(onlineBankingServices[idx])}
                    onValueChange={handleAccordionValueChange}
                  />
                </VStack>
              </VStack>
            </Layer>
          </SimpleGrid>
        </SandSection>
      )}
    </InView>
  );
};

// Styled components used in OBServices
const StyledTeaserCard = styled(TeaserCard, {
  '&:focus-visible': {
    outline: '2px solid $text-standard',
    outlineOffset: 4,
  },
});

const StyledIconLink = styled(IconLink, {
  '&:focus, &:focus-visible': {
    [`& ${Wrapper}`]: {
      outline: 'none',
    },
  },
});

// TODO: fix sand theme tokens on cblsg package
export const SandSection = styled(Section, {
  background: '$colors$petrol0 !important',
});
